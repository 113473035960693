import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../App';
import { useContext } from 'react';
import { capitalizeFirstLowercaseRest, returnFirstChar } from '../common/common';
import logo from '../../assets/images/logo.png';
import adminlogo from '../../assets/images/admin.png';
import navopen from '../../assets/images/nav-open-icon.svg';

function Header() {
  const { user } = useContext(AuthContext);

  var openNav = () => {
    document.getElementById("mySidebar").style.width = "300px";
    document.getElementById("main").style.marginLeft = "300px";
  }
  var Logout = () => {
    localStorage.clear();
    // dispatch({type:"reset",valuse:""});

  }
  console.log(user.userInfo)
  var extractFirstName = (fullName) => {
    // alert(fullName)
    if (fullName) {
      var nameParts = fullName.split(' ');

      // Return the first element (first name)
      return nameParts[0];
      // Output: John

    }
    else{
      return "";
    }

  }
  var fullName = user.userInfo.fullname;
  var firstName = extractFirstName(fullName);
  console.log(firstName);
  return (
    <>
      <header className='dash-header d-flex align-items-center justify-content-between'>
        <button className="openbtn" onClick={openNav}><img src="images/toggle-btn.png" alt="" /> <img src={navopen} className="nav icon" alt="nav icon" /> </button>
        <div className="container">
          <nav>
            <div className="row">
              <div className="col-lg-10 col-8">
                <div className="logo">
                  <Link to={"/"}>
                    {/* <img src={logo} alt="logo" /> */}
                    <img src={"/images/new_logo.png"} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-2 col-4 text-end">

                <div className='rounded-proPic'>
                  {returnFirstChar(user.userInfo.fullname ? firstName : '')}
                </div>
                {/* <button className="btn btn-secondary dropdown-toggle user-toggle" type="button" >
                        {capitalizeFirstLowercaseRest(fullName?fullName:'')}
                </button> */}

                {/* <img className="admin-img" src={adminlogo} alt="admin" />Admin */}


                {/* <img className="admin-img" src={adminlogo} alt="admin" />Admin */}
                {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className="admin-img" src={adminlogo} alt="admin" />Admin
                      <i className="fa fa-angle-down ml-2 opacity-8" />
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="#">Profile</Link></li>
                      <li><Link className="dropdown-item" onClick={e=>localStorage.clear()} to="/">Logout</Link></li>
                    </ul>
                  </li>
                </ul> */}
              </div>
            </div>
          </nav>
        </div>
      </header>

    </>
  )
}

export default Header