import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

import "./assets/css/style.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createContext, useState } from "react";
import BasePage from "./compoments/pages/BasePage";
import Home from "./compoments/pages/Home";
import Login from "./compoments/pages/Login";
import Signup from "./compoments/pages/Signup";
import ForgetPassword from "./compoments/pages/ForgetPassword";
import ResetPassword from "./compoments/pages/PasswordReset";
import DocumentPage from "./compoments/pages/Document";
import CompareDoc from "./compoments/pages/CompareDoc";
import TokenHelper from "./services/TokenHelper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginAuth, CheckAuth } from "../src/middleware/LoginAuth";
import PasswordOtp from "./compoments/pages/PasswordOtp";
import UserAccount from "./compoments/pages/UserAccount";
import UserActivity from "./compoments/pages/UserActivity";
import PrivacyPolicy from "./compoments/pages/PrivacyPolicy";
import Support from "./compoments/pages/Support";
import OtpPage from "./compoments/pages/OtpPage";

export const AuthContext = createContext();
var initialValue = {};
function App() {
  const [token, setToken] = useState(
    TokenHelper.getToken() ? TokenHelper.getToken() : ""
  );
  const [userInfo, setUserInfo] = useState(
    TokenHelper.getToken() ? TokenHelper.getUserInfo() : {}
  );
  initialValue.token = token;
  initialValue.setToken = setToken;
  initialValue.userInfo = userInfo;
  initialValue.setUserInfo = setUserInfo;
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <div className="App">
        <AuthContext.Provider value={{ user: initialValue }}>
          <BrowserRouter>
            <Routes>
              <Route
                path=""
                element={
                  <CheckAuth>
                    <Login />
                  </CheckAuth>
                }
              />
              <Route
                path="/signup"
                element={
                  <CheckAuth>
                    <Signup />
                  </CheckAuth>
                }
              />
              <Route
                path="/otp"
                element={
                  <CheckAuth>
                    <OtpPage />
                  </CheckAuth>
                }
              />
              <Route
                path="/forgetpass"
                element={
                  <CheckAuth>
                    <ForgetPassword />
                  </CheckAuth>
                }
              />
              <Route
                path="/resetpass"
                element={
                  <CheckAuth>
                    <ResetPassword />
                  </CheckAuth>
                }
              />
              <Route
                path="/otppass"
                element={
                  <CheckAuth>
                    <PasswordOtp />
                  </CheckAuth>
                }
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />

              <Route path="/home" element={<BasePage />}>
                <Route
                  index
                  element={
                    <LoginAuth>
                      <Home />
                    </LoginAuth>
                  }
                />
                <Route
                  path="Docs"
                  element={
                    <LoginAuth>
                      <DocumentPage />
                    </LoginAuth>
                  }
                />
                <Route
                  path="finaldoc"
                  element={
                    <LoginAuth>
                      <CompareDoc />
                    </LoginAuth>
                  }
                />
                <Route
                  path="account"
                  element={
                    <LoginAuth>
                      <UserAccount />
                    </LoginAuth>
                  }
                />

                <Route
                  path="activity"
                  element={
                    <LoginAuth>
                      <UserActivity />
                    </LoginAuth>
                  }
                />

                <Route
                  path="support"
                  element={
                    <LoginAuth>
                      <Support />
                    </LoginAuth>
                  }
                />

                

              </Route>

            </Routes>
          </BrowserRouter>
        </AuthContext.Provider>
        {/* <Login></Login> */}
      </div>
    </>
  );
}

export default App;
