import * as yup from "yup";

export const LoginSchama = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(8).required()
});
export const SignupSchama = yup.object({
    fullname: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
    // conpassword: yup.string().min(8).required()
    conpassword: yup.string().trim().min(8,"confirm password lengt must be 8 characters").required().oneOf([yup.ref("password")], "confirm password not match"),
});
export const ForgotPasswordSchama = yup.object({
    email: yup.string().email().required(),
});
export const PasswordOtpSchama = yup.object({
    code:yup.string().required(),
});
export const ResetPasswordSchama = yup.object({
    password: yup.string().min(8).required(),
    conpassword: yup.string().trim().min(8,"confirm password lengt must be 8 characters").required().oneOf([yup.ref("password")], "confirm password not match"),
});
export const AccountSchama = yup.object({
    // fullname: yup.string().required(),
    password: yup.string().min(8),
    newpassword:yup.string().min(8),
    conpassword: yup.string().trim().min(8,"confirm password lengt must be 8 characters").oneOf([yup.ref("newpassword")], "confirm password not match"),
});
export const SupportSchama = yup.object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
});
export const UploadSchama = yup.object({
    file: yup.mixed().test("required", "Please select a file", value => {
        return value && value.length;
    }).test({
        message: 'Please provide a supported file type (doc,docx)',
        test: (file, context) => {
            console.log(file);
           
                const isValid = ['doc', 'docx'].includes(getExtension(file[0]?.name));
                if (!isValid) context?.createError();
                return isValid;
           
        }
    }),
});

function getExtension(path) {

    if (path !== undefined) {

        var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
            // (supports `\\` and `/` separators)
            pos = basename.lastIndexOf(".");       // get last position of `.`

        if (basename === "" || pos < 1)            // if file name is empty or ...
            return "";                             //  `.` not found (-1) or comes first (0)

        return basename.slice(pos + 1);            // extract extension ignoring `.`
    } else {
        return "";
    }
}

