import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserService from '../../services/User.Service';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordSchama } from '../pages/Schemas';
import { AuthContext } from '../../App';
import { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import TokenHelper from '../../services/TokenHelper';
import StartedIcon from '../../assets/images/get-started.png';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';



function ResetPassword() {

  const storedEmail = localStorage.getItem('email');
  console.log(storedEmail,'storedEmail')
  var location=useLocation();
  console.log("sate ",location.state);
  var [varifyData,setVarifyData]=useState({});
  const navigate = useNavigate();

  const dataSubmit = async (data) => {
    var fdata = new FormData();
    fdata.append("password", data.password);
    fdata.append("email", storedEmail);
    fdata.append("conpassword", data.conpassword);
    var response = await UserService.resetpassword(fdata);
    // alert(response,'response')
    if (response.data.status == false) {
      toast.error(response.data.msg)
  }
    if (response.data.status) {
      navigate(`/`)
      toast.success(response.data.msg)

    }

    console.log(response.data)

  }
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(ResetPasswordSchama),
    mode: "all"
  });
  useEffect(()=>{
    if(location.state === null)
    {
     
      navigate(-1)
    }else{
      // console.log(location.state.datatrue)
      if(location.state.datatrue )
      {
      //  alert(location.state.email)
        setVarifyData({
          email:location.state.email
        })
       

      }else{
        navigate(-1)
      }
    }
  },[])
  return (
    <>
      {/* =================== status-publish ================================ */}
      <section className="login-sec">
        <Container>
            <Row>
            <Col lg={3}></Col>
              <Col lg={6}>
              <div className="login-div forgot-password-sec">
                <div className="login-area">
                  {/* <div className="logo text-center">
                    <img src={logo} alt="logo" />
                  </div> */}
                  <div className="login-form">
                  <h2 className="text-center">New Password</h2>
                    {/* <span>Already have an account?<Link to="#">Sign In</Link></span> */}
                    <Form action='' onSubmit={handleSubmit(dataSubmit)}>

                      <div className="form-row">

                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>Create New Password</Form.Label>
                          <Form.Control
                            type="password" class="form-control"
                            {...register("password")}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>Confirm Your Password</Form.Label>
                          <Form.Control
                            type="password" class="form-control"
                            {...register("conpassword")}
                            isInvalid={!!errors.conpassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.conpassword?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group col-lg-12">
                          <Button type='submit' className='submit'>Change</Button>
                        </Form.Group>
                      </div>
                    </Form>
                  </div>
                </div>
                </div>
              </Col>
              <Col lg={3}>
                
              </Col>
            </Row>
        </Container>
      </section>

    </>
  )
}

export default ResetPassword