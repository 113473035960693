import TokenHelper from "../services/TokenHelper";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";

export const LoginAuth = ({ children }) => {
  const token = TokenHelper.getToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ path: location.pathname }} />;
  }

  return children;
};


export const CheckAuth = ({ children }) => {
  const token = TokenHelper.getToken();
  const location = useLocation();

  if (token) {
    return <Navigate to="/home" state={{ path: location.pathname }} />;
  }

  return children;
};
