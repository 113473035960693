import { Navigate } from "react-router-dom";

class TokenHelper {
  state = {
    language_type: "",
  };

  constructor() {
    // this._accessToken = "oko";
  }

  getHeader() {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer "+this.getToken() //this.getToken(),
      },
    };
  }

  setToken(payload){
    localStorage.setItem("token",payload)
  }

  getToken(){
    return localStorage.getItem("token")
  }

  // setAstroInfo(payload){
  //   localStorage.setItem("astro_info",JSON.stringify(payload))
  // }

  // getAstroInfo(){
  //   var data=JSON.parse(localStorage.getItem("astro_info"));
  //   return data;
  // }


  setUserInfo(payload){
    localStorage.setItem("user_info",JSON.stringify(payload))
  }

  getUserInfo(){
    var data=JSON.parse(localStorage.getItem("user_info"));
    return data;
  }
  setLogoutInfo(){
    localStorage.clear();
  }

   
}

export default new TokenHelper();
