import React from 'react'
import { Outlet } from 'react-router-dom'
// import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'


function BasePage() {
  return (
   <>
        {/* <Header /> */}
        {/* <Outlet /> */}
        {/* <Footer /> */}
        <Sidebar />
        <div id="main">
            <Header />
          <Outlet />
        </div>
   </>
  )
}

export default BasePage