import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserService from '../../services/User.Service';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { SupportSchama } from '../pages/Schemas';
import { AuthContext } from '../../App';
import { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import TokenHelper from '../../services/TokenHelper';
import StartedIcon from '../../assets/images/get-started.png';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';



function Support() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const dataSubmit = async (data) => {
        var fdata = new FormData();
        fdata.append("firstname", data.firstname);
        fdata.append("lastname", data.lastname);
        fdata.append("email", data.email);
        fdata.append("phoneno", data.phoneno);
        fdata.append("message", data.message);
        var response = await UserService.support(fdata);
        // alert(response,'response')
        console.log(response,'response')
        if (response.data.status == false) {
            toast.error(response.data.msg)
        }
        if (response.data.status) {
            navigate(`/home`)
            toast.success(response.data.msg)

        }

        console.log(response.data)

    }
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(SupportSchama),
        mode: "all"
    });
    return (
        <>
            {/* =================== status-publish ================================ */}
            <section className="login-sec support-login">
                <Container>
                    <Row>
                       
                        <Col lg={12}>
                            <div className="login-div login-div-custom forgot-password-sec">
                                <div className="login-area">
                                    {/* <div className="logo text-center">
                                        <img src={logo} alt="logo" />
                                    </div> */}
                                    <div className="login-form">
                                        <h2 class="text-center">Support</h2>
                                        {/* <span>Already have an account?<Link to="#">Sign In</Link></span> */}
                                        <Form action='' onSubmit={handleSubmit(dataSubmit)}>
                                            <div className="form-row">
                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>First Name <span class="error">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text" className="form-control"
                                                        {...register("firstname")}
                                                        isInvalid={!!errors.firstname}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstname?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>Last Name <span class="error">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text" className="form-control"
                                                        {...register("lastname")}
                                                        isInvalid={!!errors.lastname}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastname?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>Email <span class="error">*</span></Form.Label>
                                                    <Form.Control
                                                        type="email" className="form-control" 
                                                        {...register("email")}
                                                        isInvalid={!!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>Phone (optional)</Form.Label>
                                                    <Form.Control
                                                        type="number" className="form-control"
                                                        {...register("phoneno")}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>Message <span class="error">*</span></Form.Label>
                                                    <textarea className="form-control" {...register("message")}  isInvalid={!!errors.message}></textarea>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.message?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="">
                                                    <Button type='submit' className='submit'>Submit</Button>
                                                </Form.Group>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3}>

                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Support