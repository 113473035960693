import React, { useContext, useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { UploadSchama } from "../pages/Schemas";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../App";
// import { useContext } from 'react';
import { useParams, useLocation } from "react-router-dom";
import FileIcon from "../../assets/images/file-icon.svg";
import Uploadimage from "../../assets/images/upload.svg";
import Loader from "./Loader";
import DocumentShow from "../common/DocumentShow";
import { useNavigate } from "react-router-dom"
import UserService from '../../services/User.Service';
import { Container, Row, Col } from "react-bootstrap";


function DocumentView() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  var location = useLocation();
  console.log(location);
  var oldlink = location.state.link1;
  var finallink = location.state.link2;
  var final_documrent = location.state.link3;
  // console.log(oldlink)
  console.log(final_documrent)
  const oldDocs = [
    {
      uri: `${oldlink}`,
    },
  ];

  const newDocs = [
    {
      uri: `${finallink}`,
    },
  ];


  // console.log(docs);
  return (
    <>
      {/* =================== status-publish ================================ */}
      <section className="doc-comparison">
        <Container>
          <Row className="justify-content-center"></Row>
          <Col ms={12} className={"text-center"}>

            <h1>Your Document Is Ready</h1>
            <div className="doc-compare-div">
              <Row>

                <Col md={6}>

                  <div className="doc-read-box">
                    <h4>Original Document </h4>

                    <DocumentShow docs={oldDocs} />

                    {/**    <iframe
                          src={"https://docs.google.com/viewer?url=" + newDocs + "&embedded=true"}
                          title="file"
                          width="100%"
                          height="100%"
                        ></iframe> */}

                  </div>

                </Col>

                <Col md={6}>

                  <div className="doc-read-box">
                    <h4>Chat-GPT Document</h4>

                    <DocumentShow docs={newDocs} />

                    {/**   <iframe
                            src={"https://docs.google.com/viewer?url=" + finallink + "&embedded=true"}
                            title="file"
                            width="100%"
                            height="100%"
                          ></iframe> */}
                  </div>

                </Col>

                <Col md={12}>

                  <div className="doc-read-btn">


                    <Link className="btn" to={`/home/finaldoc`} state={{ link3: final_documrent }} > Compare</Link>
                  </div>

                </Col>
              </Row>
            </div>

          </Col>

        </Container>
      </section>
    </>
  );
}

export default DocumentView;
