import React, { useCallback, useState } from 'react';
// import './app.css';
import User from './User';
import UserService from '../../services/User.Service';
import { AuthContext } from '../../App';
import { useNavigate } from "react-router-dom"
import { useContext } from 'react';
import TokenHelper from '../../services/TokenHelper';
import { toast } from 'react-toastify';
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialApple

} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
  AppleLoginButton
} from 'react-social-login-buttons';
import Loader from './Loader';





// import { ReactComponent as PinterestLogo } from './assets/pinterest.svg';

// const REDIRECT_URI = 'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
// const REDIRECT_URI = 'https://chatgpt.elvirainfotech.org/'
const REDIRECT_URI = 'https://dcgpt-workspace-open-8029.gitkube.com/'
// const REDIRECT_URI = 'http://localhost:3001/'

const SocialLoginButtons2 = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState({ status: false, data: {} });
  const [loader, setLoader] = useState(false)

  const onLoginStart = useCallback(() => {
    // alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    // alert('logout success');
  }, []);

  const onLogout = useCallback(() => { }, []);



  const apiCall = useCallback(async (data, provider) => {

    setLoader(true)

    var form = new FormData();
    if (provider == "apple") {



      var response = await UserService.appleAuth({ authorization_code: data.authorization.code, id_token: data.authorization.id_token, provider: provider });

      console.log("apple verify data ", response.data);

      var ndata = response.data.data;


      form.append("email", ndata.email);
      form.append("name", "");
      form.append("provider", provider)

    }
    else {

      form.append("email", data.email);
      form.append("name", data.name);
      form.append("provider", provider);

    }



    var response = await UserService.loginwithgoogle(form);
    // alert(response,'response')
    if (response.data.status == false) {
      setLoader(false)
      toast.error(response.data.msg)
    }
    if (response.data.status) {
      // TokenHelper.setToken(response.data.token)
      // TokenHelper.setUserInfo(response.data.data)
      // user.setToken(response.data.token)
      // user.setUserInfo(response.data.data[0])
      // TokenHelper.setToken(response.data.token)
      // TokenHelper.setUserInfo(response.data.data[0])
      // navigate(`/home`)
      // toast.success(response.data.msg)
      setLoader(false)
      TokenHelper.setToken(response.data.token);
      TokenHelper.setUserInfo(response.data.data[0]);
      user.setToken(response.data.token);
      user.setUserInfo(response.data.data[0]);


      navigate(`/home`)
      toast.success(response.data.msg)

    }

    console.log(response.data)


  }, [])

  return (
    <>
      {loader && <Loader />}
      {provider && profile && (
        <User provider={provider} profile={profile} onLogout={onLogout} />
      )}
      <div className={`App ${provider && profile ? 'hide' : ''}`}>
        <div className='social-size'>


          <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID}
            fieldsProfile={
              'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={REDIRECT_URI}
            onResolve={async ({ provider, data }) => {
              console.log("facebook ", data)
              // setProvider(provider);
              // setProfile(data);

              apiCall(data, provider)

            }}
            onReject={err => {
              console.log(err);
            }}
          >

            <FacebookLoginButton className='your-button-selector' />
          </LoginSocialFacebook >

          <LoginSocialGoogle
            client_id={process.env.REACT_APP_GG_APP_ID}
            onLoginStart={onLoginStart}
            redirect_uri={REDIRECT_URI}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={async ({ provider, data }) => {

              console.log("google ", data)
              // var aa={status:true,data:data}
              // setProfile({...profile,aa});
              apiCall(data, provider)
              // const dataSubmit = async (data) => {
              // var fdata = new FormData();
              // fdata.append("email", data.email);
              // fdata.append("password", data.password);


              // }
              // setProvider(provider);
              // setProfile(data);
            }}
            onReject={err => {
              console.log(err);
            }}
          >
            <GoogleLoginButton className='your-button-selector1' />
          </LoginSocialGoogle>

          <LoginSocialApple
            client_id={process.env.REACT_APP_APPLE_APP_ID}
            scope={"name email"}
            redirect_uri={REDIRECT_URI}
            onLoginStart={onLoginStart}
            onResolve={async ({ provider, data }) => {
              console.log(provider);
              console.log('apple ', data)
              // setProvider(provider);
              // setProfile(data);
              apiCall(data, provider);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <AppleLoginButton className="your-button-selector2" />

          </LoginSocialApple>




        </div>
      </div>
    </>
  );
};

export default SocialLoginButtons2;