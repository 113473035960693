import React from 'react';
import AppleLogin from 'react-apple-login';

const AppleLoginButton = () => {
  const handleAppleLogin = async (response) => {
    // Handle the response from Apple login
    // You can send the response to your backend for further processing
    console.log(response);
  };

  return (
    <AppleLogin
      clientId="your-client-id"
      redirectURI="your-redirect-uri"
      responseType="code"
      responseMode="form_post"
      scope="email"
      
      render={(props) => (
        <button onClick={props.onClick}>
          <i class="fa fa-apple" aria-hidden="true"></i>
        </button>
      )}
      onSuccess={handleAppleLogin}
      onFailure={console.error}
    />
  );
};

export default AppleLoginButton;
