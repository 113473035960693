import React, { useRef, useState } from 'react';
import UserService from '../../services/User.Service';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadSchama } from '../pages/Schemas';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom"
import { AuthContext } from '../../App';
import { useContext } from 'react';
import FileIcon from '../../assets/images/file-icon.svg';
import Uploadimage from '../../assets/images/upload.svg';
import Loader from './Loader';


import { Container, Row, Col, Form, Button } from 'react-bootstrap';


function Home() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [filename, setFilename] = useState("Select Your Document");
  const [msg, setMsg] = useState('');

  const { register, handleSubmit, reset, formState: { errors }, watch } = useForm({
    resolver: yupResolver(UploadSchama)
  });

  // const [state,setState] = useState(false);
  //  console.log(user)
  const dataSubmit = async (data) => {
    try {
      setLoader(true);
      // console.log(data.file[0]);
      var fdata = new FormData();
      fdata.append("user_id", user.userInfo.id);
      fdata.append("file", data.file[0]);
      var response = await UserService.uploaddata(fdata);

      // alert(response,'response')
      console.log(response.data)


      if (response.data.status == true) {
        // var oldlink = response.data.old_file_link
        // var newlink = response.data.new_file_link
        // var finallink = response.data.final_file_link
        // navigate(`/home/Docs`, { state: { link1: oldlink, link2: newlink, link3: finallink } })

        setLoader(false);
        // toast.success(response.data.msg)
        setMsg(response.data.msg)


      }
      else if (response.data.status === "error") {
        setLoader(false);
        toast.warning(response.data.msg)
      }
      else {
        setLoader(false);
        toast.error("please try again something went wrong")
      }

      reset();
      setFilename("Select Your Document");

    } catch (e) {
      setLoader(false);
      toast.error("please try again something went wrong")

      reset();
      setFilename("Select Your Document");
    }

  }

  return (
    <>
      {loader && <Loader />}
      {/* =================== status-publish ================================ */}
      <section>
        <Container>
          <Row>
            <Col className='text-center' md={12}>
              <div className="upload text-center">
                <img src={Uploadimage} alt="upload" />
                <h4>Upload Your Document</h4>
                <span>In .doc or .docx formatOnly</span>
                <div className="upload-form">
                  <Form action='' onSubmit={handleSubmit(dataSubmit)}>

                    <label className="uploadFile">
                      <img src={FileIcon} alt="upload" />
                      <span className="filename">{filename}</span>
                      <input type="file" className="inputfile form-control" {...register("file")} onChange={e => setFilename((e.target.files && (e.target.files[0] ? e.target.files[0].name : "Select Your Document")))} accept=".doc,.docx" />

                    </label>
                    <p style={{ color: 'red' }} className='form-field-error'>{errors.file?.message}</p>
                    <Button type="submit" className="btn">Proofread</Button>
                  </Form>
                </div>

                <br />
                <p className='successMSG'>{msg}</p>

              </div>

              <div className="login-form mb-4">

                <h6 className='pb-2'>ChatGPT - powered. Your data is safe with us. Neither DocuEagle nor ChatGPT stores your data nor shares it with third parties. We erase your document after completing the proofreading.</h6>


                <hr width="50%" style={{ margin: "0 auto" }} className='pb-2' />


                <Link to="/privacy-policy">Privacy-Policy</Link>

              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  )
}

export default Home