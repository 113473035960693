import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// import FileViewer from "react-file-viewer";

function DocumentShow(props) {


    //   return <FileViewer fileType={"docx"} filePath={props.link} />;

    var config = { 
        header: { 
            disableHeader: true, 
            disableFileName: false,
            retainURLParams: false 
        } 
    }


return <DocViewer config={config} documents={props.docs} pluginRenderers={DocViewerRenderers} />;

}

export default DocumentShow;