import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import UserService from "../../services/User.Service";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountSchama } from "../pages/Schemas";
import { AuthContext } from "../../App";
import { useContext } from "react";
import logo from "../../assets/images/logo.png";
import TokenHelper from "../../services/TokenHelper";
import StartedIcon from "../../assets/images/get-started.png";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

function UserAccount() {
  const { user } = useContext(AuthContext);
  // var fulldataname=user.userInfo.fullname
  // const [userdata, setUserdata] = useState({ fullname:fulldataname});
  console.log("userinfo  ", user.userInfo);
  var email = user.userInfo.email;

  const navigate = useNavigate();
  // const handleInputChange = (event) => {
  //     setUserdata({ ...userdata, [event.target.fullname]: event.target.value });
  //   };
  const dataSubmit = async (data) => {
    // data.preventDefault();
    console.log(data);
    var fdata = new FormData();
    fdata.append("fullname", data.fullname);
    fdata.append("email", email);
    fdata.append("password", data.password);
    fdata.append("newpassword", data.newpassword);
    fdata.append("conpassword", data.conpassword);
    fdata.append("provider", user.userInfo.login_with);
  
    var response = await UserService.useraccount(fdata);
    // alert(response,'response')
    if (response.data.status == false) {
      toast.error(response.data.msg);
    }
    if (response.data.status) {
      toast.success(response.data.msg);
      const name = "fullname";
      const value = data.fullname;
      user.setUserInfo({...user.userInfo,[name]: value});

      var locauserInfo=TokenHelper.getUserInfo();
      locauserInfo.fullname=data.fullname;

      TokenHelper.setUserInfo(locauserInfo);
      

    }

    console.log(response.data);

  };

  // console.log(userdata,'userdata')
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(AccountSchama),
    mode: "all",
  });

  useEffect(() => {
    reset({
      email: user.userInfo.email,
      fullname:user.userInfo.fullname
    });
  }, []);

  return (
    <>
      {/* =================== status-publish ================================ */}
      <section className="login-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="login-div account-setting forgot-password-sec">
                <div className="login-area login-area-center">
                  {/* <div className="logo text-center">
                                        <img src={logo} alt="logo" />
                                    </div> */}
                  <div className="login-form">
                    {/* <h2 className="text-center">Hello, User</h2> */}
                    {/* <span>Already have an account?<Link to="#">Sign In</Link></span> */}
                    <Form
                      className="otp-form"
                      action=""
                      onSubmit={handleSubmit(dataSubmit)}
                    >
                      <div className="form-row">
                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>FullName</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            {...register("fullname")}
                            isInvalid={!!errors.fullname}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            className="form-control"
                            {...register("email")}
                            type="email"
                            disabled
                          />
                        </Form.Group>

                        {/** ========== provider check ========  */}

                        {user.userInfo.login_with == 'email' && <>

                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            className="form-control"
                            {...register("password")}
                            type="password"
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password?.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            className="form-control"
                            {...register("newpassword")}
                            type="password"
                            isInvalid={!!errors.newpassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.newpassword?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            className="form-control"
                            {...register("conpassword")}
                            type="password"
                            isInvalid={!!errors.conpassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.conpassword?.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        </>}

                        <Form.Group className="form-group col-lg-12">
                          <Button type="submit" className="submit">
                            Update
                          </Button>
                        </Form.Group>

                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default UserAccount;
