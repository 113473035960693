import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserService from '../../services/User.Service';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignupSchama } from '../pages/Schemas';
import { AuthContext } from '../../App';
import { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import TokenHelper from '../../services/TokenHelper';
import StartedIcon from '../../assets/images/get-started.png';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';



function Signup() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const dataSubmit = async (data) => {
     
        var fdata = new FormData();
        fdata.append("fullname", data.fullname);
        fdata.append("email", data.email);
        fdata.append("password", data.password);
        fdata.append("confirm_password", data.conpassword);
        var response = await UserService.signup(fdata);
        // alert(response,'response')
        if (response.data.status == false) {
            toast.error(response.data.msg)
        }
        if (response.data.status) {
            navigate(`/otp`,{ state: { datatrue:true } })
            // toast.success(response.data.msg)

        }

        console.log(response.data)

    }
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(SignupSchama),
        mode: "all"
    });
    return (
        <>
            {/* =================== status-publish ================================ */}
            <section className="login-sec">
                <Container>
                <div className="login-div">
                    <Row>
                        <Col lg={6}>
                            <div className="login-area">
                                <div className="logo">
                                    {/* <img src={logo} alt="logo" /> */}
                                    <img src={"/images/new_logo.png"} alt="logo" />  
                                </div>
                                <div className="login-form">
                                    <h1>Please Register</h1>
                                    <span>
                                        Already have an account? <Link to={"/"}>Sign In</Link>
                                    </span>
                                    <Form className='signup-form' action='' onSubmit={handleSubmit(dataSubmit)}>
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>FullName</Form.Label>
                                                    <Form.Control
                                                        type="text" className="form-control"
                                                        {...register("fullname")}
                                                        isInvalid={!!errors.fullname}
                                                        
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.fullname?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group col-lg-12">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email" className="form-control" 
                                                        {...register("email")}
                                                        isInvalid={!!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group col-lg-12" >
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control
                                                        type="password" className="form-control"
                                                        {...register("password")}
                                                        isInvalid={!!errors.password}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group col-lg-12" >
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control
                                                        type="password" className="form-control"
                                                        {...register("conpassword")}
                                                        isInvalid={!!errors.conpassword}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.conpassword?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} className="form-group col-lg-12">
                                                <Button className="submit" type="submit">
                                                    Sign Up
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="login-image">
                                <img className="img-fluid" src={StartedIcon} alt="get strted" />
                            </div>
                        </Col>
                    </Row>
                </div>
                </Container>
            </section>

        </>
    )
}

export default Signup