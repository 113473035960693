import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import UserService from "../../services/User.Service";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountSchama } from "../pages/Schemas";
import { AuthContext } from "../../App";
import { useContext } from "react";
import logo from "../../assets/images/logo.png";
import TokenHelper from "../../services/TokenHelper";
import StartedIcon from "../../assets/images/get-started.png";

import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";

import { useQuery } from "@tanstack/react-query";

function UserActivity() {
  const { user } = useContext(AuthContext);
  //   const [activityData, setactivityData] = useState([]);
  const navigate = useNavigate();
  let { id } = user.userInfo;

  var getactivityData = async () => {
    // console.log(id, "id");
    if (user?.userInfo?.id) {
      var response = await UserService.useractivity(Number(user.userInfo.id));
      console.log("react-query ", response.data);
      // setactivityData(response.data.data);
      return response.data.data;
    }
  };
  // console.log("userid ",ChatData[0].uid)

  // Queries
  const { data: activityData } = useQuery({
    queryKey: ["user_activityData"],
    queryFn: getactivityData,
  });

  //   useEffect(() => {
  //     getactivityData(id);
  //   }, []);

  //   console.log("dataall ", activityData.data);

  return (
    <>
      {/* =================== status-publish ================================ */}
      <section className="login-sec">
        <Container>
          <Row>
            {/* <Col lg={3}></Col> */}
            <Col lg={12}>
              <div className="login-div  activity forgot-password-sec">
                <div className="login-area">
                  {/* <div className="logo text-center">
                                        <img src={logo} alt="logo" />
                                    </div> */}

                  <h2 className="text-center">User Activity</h2>
                  {/* <span>Already have an account?<Link to="#">Sign In</Link></span> */}
                  <div className="table-responsive">
                    <Table bordered className="activity-table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Document Name</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activityData?.length > 0 &&
                          activityData?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.original_file_name}</td>
                                <td>
                                  {moment(item.created_at).format("DD/MM/yy")}
                                </td>
                                <td>
                                  {moment(item.created_at).format("HH:mm:ss")}
                                </td>

                                <td>
                                  {item.status !== "1"
                                    ? "Not Complete"
                                    : "Complete"}
                                </td>
                              </tr>
                            );
                          })}
                        {activityData?.length == 0 && (
                          <>
                            <tr>
                              <td colSpan={"6"} className="text-center">
                                No Report Found
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default UserActivity;
