import axios from "axios";

import TokenHelper from './TokenHelper';

const REACT_APP_API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;

class UserService {


  login(data) {
    return axios.post(REACT_APP_API_SERVICE_URL + '/login', data, {
      headers: {
        "Content-Type": "application/json"
      },
    });
  }

  resendOTP(data) {
    return axios.post(REACT_APP_API_SERVICE_URL + '/resend-otp', data, {
      headers: {
        "Content-Type": "application/json"
      },
    });
  }

  signup(data) {
    return axios.post(REACT_APP_API_SERVICE_URL + '/register', data, TokenHelper.getHeader());
}

forgetpassword(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/verify', data, TokenHelper.getHeader());
}
otppassword(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/postforgetotp', data, TokenHelper.getHeader());
}
otp(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/postotp', data, TokenHelper.getHeader());
}
resetpassword(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/resetpass', data, TokenHelper.getHeader());
}
useraccount(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/account', data, TokenHelper.getHeader());
}
useractivity(user_id) {
  return axios.get(REACT_APP_API_SERVICE_URL + '/user-activity-list/'+user_id, TokenHelper.getHeader());
}

loginwithgoogle(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/google-login', data, TokenHelper.getHeader());
}

appleAuth(data){
  var form=new FormData();
  form.append("authorization_code",data.authorization_code);
  form.append("provider",data.provider) 
  form.append("authorization_id_token",data.id_token) 

  return axios.post(REACT_APP_API_SERVICE_URL + '/apple-auth', form, TokenHelper.getHeader());
}





support(data) {
  return axios.post(REACT_APP_API_SERVICE_URL + '/support', data, TokenHelper.getHeader());
}
uploaddata(data)
{
  // console.log(TokenHelper.getHeader());
  return axios.post(REACT_APP_API_SERVICE_URL + '/upload', data, TokenHelper.getHeader());
}
  // getData(payload)
  // {
  //   // console.log(TokenHelper.getHeader());
  //   return axios.get(REACT_APP_API_SERVICE_URL + '/finaldoc',payload, TokenHelper.getHeader());
  // }

}

export default new UserService();