import React, { useContext } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/white-logo.png';
import { signOut } from "firebase/auth";
import { firebase_auth } from "../../FirebaseSetup";
import { AuthContext } from '../../App';
import TokenHelper from '../../services/TokenHelper';


function Sidebar() {

  const { user: ContextUserAuth } = useContext(AuthContext);

  var navigate = useNavigate();


  var closeNav = () => {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }
  var openNav = () => {
    document.getElementById("mySidebar").style.width = "300px";
    document.getElementById("main").style.marginLeft = "300px";
  }


  var Logout = async() => {
    await signOut(firebase_auth);
    ContextUserAuth.setToken("");
    ContextUserAuth.setUserInfo({});
    localStorage.clear();
    sessionStorage.clear();
    navigate("/")
    // dispatch({type:"reset",valuse:""});
  }

  return (
    <>
      <div id="mySidebar" className="sidebar">
        <div className="logo-area d-flex align-items-center justify-content-between">
          <Link to="/home">
            {/* <img src={logo} className="white-logo" alt="white logo" /> */}
            <img src={"/images/new_logo.png"} className="white-logo" alt="logo" />
          </Link>
          <button className="closebtn border-0" onClick={closeNav}>&times;</button>
        </div>
        <div className='button-listing'>
          <h4 className="dashboard-heading" onClick={() => navigate('/home')}><span><i className="fa fa-tachometer"></i></span> Dashboard</h4>
          {/* <NavLink className="active" to={'/'}><button className='active-page'> <span className='rate-icon'></span> Rate </button></NavLink> */}
          <NavLink to={'/home/account'}><button> <span className='booking-icon'></span> Account Setting </button></NavLink>
          <NavLink to={'/home/activity'}> <button> <span className='tracking-icon'></span>User Activity </button> </NavLink>
          <NavLink to={'/home/support'}> <button> <span className='payment-icon'></span> Support </button> </NavLink> 
          {/* <NavLink to={'/home/privacy-policy'}> <button> <span className='tracking-icon'></span>Privacy Policy </button> </NavLink> */}
          <NavLink to={'/'} onClick={Logout}> <button> <span className='logout-icon'></span> Logout </button> </NavLink>
        </div>
      </div>
    </>
  )
}

export default Sidebar
