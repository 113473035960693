import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import UserService from "../../services/User.Service";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchama } from "./Schemas";
import { AuthContext } from "../../App";
import { useContext } from "react";
import logo from "../../assets/images/logo.png";
import TokenHelper from "../../services/TokenHelper";
import StartedIcon from "../../assets/images/get-started.png";
// import FacebookLoginButton from './FacebookLogin';
import GoogleLoginButton from "./GoogleLogin";
import AppleLoginButton from "./AppleLogin";
import ReactFacebookLogin from "react-facebook-login";
import ReactGoogleLogin from "react-google-login";

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Loader from "./Loader";
import SocialLogin from "./SocialLogin";



function Login(props) {
  const { user } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const onResponse = (resp) => {
    console.log(resp);
  };

  const dataSubmit = async (data) => {
    var fdata = new FormData();
    fdata.append("email", data.email);
    fdata.append("password", data.password);
    var response = await UserService.login(fdata);
    // alert(response,'response')
    if (response.data.status == false) {
      if (response.data.data.length > 0) {
        if (response.data.data[0].status == 0) {
          var form = new FormData();
          form.append("email", response.data.data[0].email);
          var otpres = await UserService.resendOTP(form);

          if (otpres.data.status) {
            toast.success(otpres.data.msg);
            navigate(`/otp`, { state: { datatrue: true } });
          }
        }
      } else {
        toast.error(response.data.msg);
      }
    }
    if (response.data.status) {
      TokenHelper.setToken(response.data.token);
      TokenHelper.setUserInfo(response.data.data[0]);
      user.setToken(response.data.token);
      user.setUserInfo(response.data.data[0]);


      window.location.replace("/home");
      //   navigate(`/home`);
      //   toast.success(response.data.msg);
    }

    console.log(response.data);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(LoginSchama),
    mode: "all",
  });

  return (
    <>
      {loader && <Loader />}
      {/* =================== status-publish ================================ */}
      <section className="login-sec">
        <Container>
          <div className="login-div">
            <Row>
              <Col lg={6}>
                <div className="login-area">
                  <div className="logo">
                    {/* <img src={logo} alt="logo" /> */}
                    <img src={"/images/new_logo.png"} alt="logo" />
                  </div>
                  <div className="login-form">
                    <h1>Get Started</h1>
                    {/* <span>New user?<Link to="/signup">Sign Up</Link></span> */}
                    {/* <span>Already have an account?<Link to="#">Sign In</Link></span> */}
                    <Form action="" onSubmit={handleSubmit(dataSubmit)}>
                      <div className="form-row">
                        <Form.Group className=" mb-4">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            {...register("email")}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email?.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className=" mb-4">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            {...register("password")}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className="text-right">
                          <Link to="forgetpass">Forgot password?</Link>
                        </div>

                        <Form.Group className="form-group">
                          <Button type="submit" className="submit">
                            Sign in
                          </Button>
                        </Form.Group>
                        <div className="text-center">
                          <span>Or Sign In Using</span>
                        </div>
                        <div className="flex-c-m">
                          {/* ///////// Social Login /////////////// */}
                          <SocialLogin />
                        </div>
                      </div>
                    </Form>
                    <div className="text-center">
                      <span>
                        New user?<Link to="/signup">Sign Up</Link>
                      </span>
                    </div>
                    <div className="text-right">
                      <Link to="/privacy-policy">Privacy-Policy</Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="login-image">
                  <img
                    className="img-fluid"
                    src={StartedIcon}
                    alt="get strted"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Login;
