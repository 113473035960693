import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration (staging) //////////////
const firebaseConfig = {
  apiKey: "AIzaSyDphDfiGTTfuTiQVkRf08fIOMW5Hw80JGM",
  authDomain: "docueagle-c9edf.firebaseapp.com",
  projectId: "docueagle-c9edf",
  storageBucket: "docueagle-c9edf.appspot.com",
  messagingSenderId: "411202459974",
  appId: "1:411202459974:web:9e42bec1bbf2f2dfd92d47",
  measurementId: "G-4ZMHHD4TKG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebase_auth=getAuth(app);