import React, { useContext, useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import UserService from "../../services/User.Service";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { UploadSchama } from "../pages/Schemas";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../App";

import { useParams, useLocation } from "react-router-dom";
import FileIcon from "../../assets/images/file-icon.svg";
import Uploadimage from "../../assets/images/upload.svg";
import Loader from "./Loader";
import DocumentShow from "../common/DocumentShow";

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Document() {
  const { user } = useContext(AuthContext);

  var location = useLocation();

  const [finallink,setFinallink]=useState();

  // console.log(location);
  //   var finallink = data;
  // var finallink = location.state.link3;
  // console.log(oldlink)
  // console.log(finallink)

  const finalDocs = [
    {
      uri: finallink,
    },
  ];
  
  useEffect(()=>{
    setFinallink(location.state.link3);
  },[])

  //   const newDocs = [
  //     {
  //       uri: `${finallink}`,
  //     },
  //   ];

  // console.log(docs);
  return (
    <>
      {/* =================== status-publish ================================ */}

      <section className="doc-comparison final-document">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center"></Col>
            <h1>Final Document With Changes</h1>
            <div className="doc-compare-div">
              <Row>
                <Col md={12}>
                  <div className="doc-read-box">
                    <h4>Final Document</h4>
                    {/* <DocumentShow docs={finalDocs} /> */}
                    <iframe
                      src={"https://docs.google.com/viewer?url=" + finallink + "&embedded=true"}
                      title="file"
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="doc-read-btn">
                    <a className="btn" href={finallink}>Download Now</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Document;
