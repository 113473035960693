import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserService from '../../services/User.Service';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordSchama } from '../pages/Schemas';
import { AuthContext } from '../../App';
import { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import TokenHelper from '../../services/TokenHelper';
import StartedIcon from '../../assets/images/get-started.png';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';



function ForgetPassword() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const dataSubmit = async (data) => {
    // alert(data)
    var fdata = new FormData();
    fdata.append("email", data.email);
    var response = await UserService.forgetpassword(fdata);
    // alert(response,'response')
    console.log(response.data, 'response.data')
    if (response.data.status == false) {
      toast.error(response.data.msg)
    }
    if (response.data.status) {
      var datatrue = true
      navigate(`/otppass`, { state: { datatrue: true } })
      toast.success(response.data.msg)

    }

    console.log(response.data)
    localStorage.setItem('email', data.email);
  }
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(ForgotPasswordSchama),
    mode: "all"
  });
  return (
    <>
      {/* =================== status-publish ================================ */}
      <section className="login-sec forget_sec">
        <Container>
          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className="login-div forgot-password-sec">
                <div className="login-area">
                  <div className="logo text-center">
                    <img src={"/images/new_logo.png"} alt="logo" />
                  </div>
                  <div className="login-form">
                    <h2 className="text-center">Forgot Password?</h2>
                    {/* <span>Already have an account?<Link to="#">Sign In</Link></span> */}
                    <Form onSubmit={handleSubmit(dataSubmit)}>

                      <div className="form-row">

                        <Form.Group className="form-group col-lg-12">
                          <Form.Label>Enter Email Address</Form.Label>
                          <Form.Control
                            type="email" class="form-control"
                            {...register("email")}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="">
                          <Button type='submit' className='submit forget_password_btn' >Reset My Password</Button>
                        </Form.Group>
                      </div>
                    </Form>
                    <Link to={"/"} className='back_link'>Back</Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>

            </Col>
          </Row>
        </Container>
      </section>

    </>
  )
}

export default ForgetPassword