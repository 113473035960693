import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserService from '../../services/User.Service';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordOtpSchama } from '../pages/Schemas';
import { AuthContext } from '../../App';
import OTPInput from 'react-otp-input';
import { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import TokenHelper from '../../services/TokenHelper';
import StartedIcon from '../../assets/images/get-started.png';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';



function OtpPage() {
    const { user } = useContext(AuthContext);
    const [otp, setOtp] = useState('');
    var location=useLocation();
    var [varifyData,setVarifyData]=useState({});
    const navigate = useNavigate();
    // const dataSubmit = async (data) => {
    //     var code=`${data.num1}${data.num2}${data.num3}${data.num4}${data.num5}${data.num6}`;
    //     console.log(code,'code')
    //   var fdata = new FormData();
    //   fdata.append("code",code);
    //   var response = await UserService.otp(fdata);
    //   // alert(response,'response')
    //   console.log(response.data,'response.data')
    //   if (response.data.status == false) {
    //     toast.error(response.data.msg)
    // }
    //   if (response.data.status) {
    //     navigate(`/`)
    //     toast.success(response.data.msg)
  
    //   }
  
    //   console.log(response.data)
  
    // }
    // console.log(otp,'otp')
    const dataSubmit = async () => {
      // var code = `${data.num1}${data.num2}${data.num3}${data.num4}${data.num5}${data.num6}`;
      console.log(otp, 'code1')
      var fdata = new FormData();
      fdata.append("code",otp);
      var response = await UserService.otp(fdata);
      // alert(response,'response')
      // console.log(response.data, 'response.data')
      if (response.data.status == false) {
        toast.error(response.data.msg)
      }
      if (response.data.status) {
  
        navigate(`/`)
        toast.success(response.data.msg)
  
      }
  
      // console.log(response.data)
  
    }
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    useEffect(()=>{
      if(location.state === null)
      {
       
        navigate(-1)
      }else{
        
        if(location.state.datatrue)
        {
        //  alert(location.state.email)
          setVarifyData({
            email:location.state.email
          })
         
  
        }else{
          navigate(-1)
        }
      }
    },[])
    return (
      <>
            {/* =================== status-publish ================================ */}
            
            <div className="container height-100 d-flex justify-content-center align-items-center">
        <Row>
          <Col lg={12}>
            {/* <div className="logo text-center">
                    <img src={logo} alt="logo" />
                </div> */}
            <div className="position-relative">
              <div className="card otp-card p-2 text-center">
                <h6>Please enter the OTP <br></br> to verify your account</h6>
                <div> <span>A code has been sent to</span> <small>*******@gmail.com</small>
                </div>
                <Form>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  className="otpinput"
                  // {...register("code")} 
                  // renderSeparator=''
                  // renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
                {/* <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                        <Form.Group className='m-2 text-center form-control rounded'>
                          <Form.Control  type="number" id="first" {...register("num1")}  
                          isInvalid={!!errors.num1} />
                        </Form.Group>
                        <Form.Group className='m-2 text-center form-control rounded'>
                          <Form.Control  type="number" id="second" {...register("num2")}  
                          isInvalid={!!errors.num2} />
                        </Form.Group>
                        <Form.Group className='m-2 text-center form-control rounded'>
                          <Form.Control  type="number" id="third" {...register("num3")}  
                          isInvalid={!!errors.num3} />
                        </Form.Group>
                        <Form.Group className='m-2 text-center form-control rounded'>
                          <Form.Control  type="number" id="fourth" {...register("num4")}  
                          isInvalid={!!errors.num4} />
                        </Form.Group>
                        <Form.Group className='m-2 text-center form-control rounded'>
                          <Form.Control  type="number" id="fifth" {...register("num5")}  
                          isInvalid={!!errors.num5} />
                        </Form.Group>
                        <Form.Group className='m-2 text-center form-control rounded'>
                          <Form.Control  type="number" id="sixth" {...register("num6")}  
                          isInvalid={!!errors.num6} />
                        </Form.Group>
                        </div> */}
                <div className="mt-4">
                    <Button type='button' onClick={dataSubmit} className='btn btn-danger px-4 validate' >Validate</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>

        </>
    )
}

export default OtpPage