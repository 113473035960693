import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithRedirect, signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase_auth } from '../../FirebaseSetup';
import { FacebookLoginButton, GoogleLoginButton, AppleLoginButton } from 'react-social-login-buttons';
import { toast } from "react-toastify";
import Loader from './Loader';
import UserService from '../../services/User.Service';
import TokenHelper from '../../services/TokenHelper';
import { useNavigate } from "react-router-dom"
import { AuthContext } from '../../App';



function SocialLogin() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loader, setLoader] = useState(false)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [user] = useAuthState(firebase_auth);

    const { user: ContextUserAuth } = useContext(AuthContext);

    const navigate = useNavigate();


    const FbLogin = () => {
        const provider = new FacebookAuthProvider();
        // signInWithRedirect(firebase_auth, provider);
        signInWithPopup(firebase_auth, provider).catch((error) => {
            toast.error("Sorry! something went wrong")
            console.error(error)
        });
    }


    const GoogleLogin = () => {
        const provider = new GoogleAuthProvider();
        // signInWithRedirect(firebase_auth, provider);
        signInWithPopup(firebase_auth, provider).catch((error) => {
            toast.error("Sorry! something went wrong")
            console.error(error)
        });
    }


    const AppleLogin = () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        // signInWithRedirect(firebase_auth, provider);
        signInWithPopup(firebase_auth, provider).catch((error) => {
            toast.error("Sorry! something went wrong")
            console.error(error)
        });
    }


    const apiCall = async (provider, data) => {

        setLoader(true);

        provider = provider.split(".", 1)[0];

        var form = new FormData();
        form.append("email", data.email);
        form.append("name", (data.displayName ? data.displayName : ""));
        form.append("uid", data.uid);
        form.append("provider", provider);



        var response = await UserService.loginwithgoogle(form);
        // alert(response,'response')
        if (response.data.status == false) {
            setLoader(false)
            toast.error(response.data.msg)
        }
        if (response.data.status) {
            // TokenHelper.setToken(response.data.token)
            // TokenHelper.setUserInfo(response.data.data)
            // user.setToken(response.data.token)
            // user.setUserInfo(response.data.data[0])
            // TokenHelper.setToken(response.data.token)
            // TokenHelper.setUserInfo(response.data.data[0])
            // navigate(`/home`)
            // toast.success(response.data.msg)
            setLoader(false)
            TokenHelper.setToken(response.data.token);
            TokenHelper.setUserInfo(response.data.data[0]);
            ContextUserAuth.setToken(response.data.token);
            ContextUserAuth.setUserInfo(response.data.data[0]);


            window.location.replace("/home");
            //   navigate(`/home`);
            //   toast.success(response.data.msg);

        }

        console.log(response.data)


    };



    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        ////////////// fetch login user from firebase after social login ////////////////
        if (user !== null) {

            // ============ method 2 currently use =================
            user.providerData.forEach(async (profile) => {
                // setLoader(true);
                console.log("profile: ", profile)
                // console.log("Sign-in provider: " + profile.providerId);
                // console.log("  Provider-specific UID: " + profile.uid);
                // console.log("  Name: " + profile.displayName);
                // console.log("  Email: " + profile.email);
                // console.log("  Photo URL: " + profile.photoURL);
                setLoader(true);
                await apiCall(profile.providerId, profile);
                setLoader(false);
                // setLoader(false);
            });


        }
        ////////////// fetch login user from firebase after social login ////////////////
    }, [user])

    return (<>
        {loader && <Loader />}
        <div className='social-size'>
            <FacebookLoginButton className='your-button-selector' onClick={FbLogin} />

            <GoogleLoginButton className='your-button-selector1' onClick={GoogleLogin} />

            <AppleLoginButton className="your-button-selector2" onClick={AppleLogin} />
        </div>
    </>)
}

export default memo(SocialLogin);